@import url(../src/assets/fonts-inter/font-inter.css);

* {
	box-sizing: border-box;
}

:root {
	--primary: #003cff;
	--secondary: #ffc005;
	--highlight: #223349;
	/* --label:  #28373E; */
	--label: #64686A;
	
}

/***Variables Classes***/
.primary{
	color:var(--primary);
}
.primary-bg{
	background:var(--primary);
}
.secondary{
	color:var(--secondary);
}
.secondary-bg{
	color:var(--secondary);
}


body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif; */
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

header {
	background: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	padding: 10px 20px;
	position: fixed;
	top: 0px;
	width: 100%;
	left: 0;
}

.logoSize {
	max-width: 300px;
}

html,
body,
body>div{
	height: 100%;
}


body>div#popup-root{
	height: auto;
}

.bodyWrapper {
	display: block;
	height: 100%;
	width: 100%;
	background: #fff;
	/* min-height: 100vh; */
}

.mt-custom {
	margin-top: 20px;
}

.bodyWrap {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.toggle-custom .react-toggle-track {
	width: 44px;
}

.toggle-custom.react-toggle--checked .react-toggle-thumb {
	left: 24px;
}

.toggle-custom.react-toggle--checked .react-toggle-track {
	background-color: var(--primary);
}

.toggle-custom.react-toggle--checked .react-toggle-thumb {
	border-color: var(--primary);
}

.toggle-custom.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #9c9c9c;
}

.toggle-custom.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: var(--primary);
}

.title {
	font-size: 1.5rem;
	color: #263238;
	font-weight: 600;
}

/* .toggle-custom.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track{
	background-color: #223343;
} */
.design-Container {
	display: grid;
	grid-template-rows: max-content auto;
}

.h-full {
	height: 100%;
}

.talk-to-expert {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.4em;
	background-color: var(--secondary) !important;
	border: 1px solid var(--secondary) !important;
	margin: 0 !important;
	font-size: 0.8rem !important;
  padding: .5rem  !important;
}

.talk-to-expert svg {
	height: 1.2em;
	width: 1.2em;
}

.next-arrow {
	height: 1.4em;
	transform: rotate(180deg);
}

.hide-in-desktop {
	display: none !important;
}
.strip-production {
	background: linear-gradient(to right, var(--primary) 0%, #de5b12 100%);
	max-width: 200px;
	height: 28px;
	height: auto !important;
	border-radius: 50px;
	border: 2px solid #fff;
	position: absolute;
	top: 1rem;
	left: 1rem;
	font-family: Sf-Bold, sans-serif;
	display: flex;
	justify-content: space-between;
	font-size: 0.62rem;
	align-items: center;
	z-index: 1;
	padding: 0.5em 0.8em;
	width: 100%;
}
.strip-production:before {
	content: 'Max Production';
	font-size: 1em;
	color: #51250d;
}
.strip-production:after {
	content: 'Lowest Production';
	position: absolute;
	right: 1em;
	color: #fff;
}
.slider{

}
.slide-container{
	overflow: hidden;
	width: 100%;
	display: flex;
	user-select: none;
	max-width: 740px;
}
.silder-heading{
	text-align: center;
	margin-bottom: 0.7rem;
	font-size: 1.8rem;
	color: #1D3557;
}
.slider-dots{
	display: flex;
	gap: 0.5rem;
	width: fit-content;
	margin-inline: auto;
	margin-top: 2rem;
}
.slider-dots .dot{
	display: block;
	width: 0.7rem;
	height: 0.7rem;	
	background-color: #D9D9D9;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
}
.slider-dots .dot::after{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background-color: red; */
	background-color: var(--primary);
	transition: all .3s;
	scale: 0 1;
	transform-origin: left;
}
.slider-dots .dot.active{
	/* background-color: var(--primary); */
}
.slider-dots .dot.active::after{
	scale: 1 1;
	transform-origin: right;

}
.slide-content{
	text-align: center;
	color: #1D3557;
	min-width: 100%;
	width: 100%;
	/* display: none; */
	transition: all .3s;
}
/* .slide-content.active{
	display: block;
} */
@media (max-width:768px) {
	html {
		font-size: 14px;
	}

	.adjust-panel-container{
		display: flex;
		width: auto;
		justify-content: space-between;
	}

}

@media (max-width:935px) {
	.hide-in-desktop {
		display: block !important;
	}

	.hide-in-mobile {
		display: none !important;
	}

}
@media (max-width: 500px){
	.strip-production {
		width: 89%;
	}
	.btnFont0 span{
		display: none;
	}
	
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  box-sizing: border-box;
}

.button-cover {
  /* background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px; */
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  left: 0;
  bottom: 0;
  color: transparent;
  font-size: 12px;
  line-height: 1;
  padding: 2px;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 50px;
    height: 24px;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

/* .button.b2 {
  
} */

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;

	&:disabled{
		cursor: not-allowed;
	}
}

.knobs {
  z-index: 2;
  background: transparent;
  border-radius: 48px;
  border: 1.5px solid #7A8489;
}

.layer {
  width: 100%;
  transition: 0.3s ease all;
  z-index: 1;
}

#button-13 .knobs:before,
#button-13 .knobs:after,
#button-13 .knobs span {
  position: absolute;
  top: 2px;
  width:13px;
  height: 12px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  /* padding: 9px 4px; */
  border-radius: 2px;
  transition: 0.3s ease all;
}
#button-13 .knobs span {
	top:2px
}

#button-13 .knobs:before,
#button-13 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

#button-13 .knobs:before {
  content: "On";
  left: 7px;
  visibility: hidden;
  display: none;
  top: 5px;
}

#button-13 .knobs:after {
  content: "Off";
  right: 12px;
    top: 5px;
}
#button-13 .knobs span {
  width: 13px;
  height: 13px;
  /* left: 37px; */
  left: 3px;
  background-color: #7A8489;
  z-index: 2;
  border-radius: 100%;
}

#button-13 .checkbox:checked + .knobs span {
  /* left: 4px;
   */
   left: 29px;
  background-color: #fff;
}
#button-13 .checkbox:checked + .knobs:before {
  visibility: visible;
  display: block;
  color: #fff;
}
#button-13 .checkbox:checked + .knobs {
 background-color: #003CFF;
 border: 1.5px solid #003CFF;
}
#button-13 .checkbox:checked + .knobs:after {
  visibility: hidden;
  display: none;
}

#button-13 .checkbox~ .layer {
  background-color: #fff;
  border-radius:48px ;
}
.switchButton{
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    /* background: #fff; */
    border-radius: 48px;
    color: #64686A;
    font-weight: 600;
    /* padding: 6px 10px 6px; */
		font-family: Inter;
		font-weight: 500;
		/* margin-right: 14px; */
		font-size: 0.938rem;
}
.switchButton .button {
	width: 60px;
	height: 30px;
}
.switchButton .knobs {
	background: #DADBDC;
	border: 1.5px solid #DADBDC;
}
.showPanel.switchButton{
	justify-content: space-between;
	padding: 0px;
	background: transparent;
}
.switchMapping select:focus-visible {
	outline: 1px solid #ccc;
}
.stripWrapperButtons .switchButton {
  padding: 0px;
  gap: .35rem;
}
.bg-transparent.switchButton {
	background-color: transparent;
}
#button-13 .knobs:after {
	content: "Off";
	right: 13px;
	top: 5px;
	HEIGHT: 100%;
	display: flex;
	align-items: center;
	top: 0;
	color: #64686A;
	opacity: .5;
	font-size: 0.688rem;
	line-height: 1.5;
	font-weight: 500;
}
#button-13 .checkbox:checked + .knobs:before {
	content: "On";
	display: flex;
	align-items: center;
	top: 0px;
	height: 100%;
	left: 9px;
	text-transform: capitalize;
	font-size: 11px;
	font-weight: 500;
}


#button-13 .knobs span {
	width: 24px;
	height: 24px;
	background: #FFFFFF;
	box-shadow: 0px 2.29167px 9.16667px rgba(0, 0, 0, 0.16);
}
#button-13 .checkbox:checked + .knobs span {
	/* left: 4px; */
	left: 32px;
}
#button-13.without-label .checkbox:checked + .knobs:before,
#button-13.without-label .knobs:after {
  content: "";
}

#button-13.without-label{
	width: 48px;
	height: 29px;
	padding: 3px 0px 3px 6px;
	box-sizing: border-box;
	background: transparent;
}
.switchButton .without-label  .knobs{
	margin: 6px 4px;
	box-sizing: border-box;
	width: 40px;
}
#button-13.without-label .knobs span{
	width: 24px;
	height: 24px;
	top: -5px;
	left: -1px;
}
#button-13.without-label .checkbox:checked + .knobs span{
	top: -5px;
	left: 16px;
}
#button-13.without-label .checkbox~ .layer{
	background: #f3f5f7;
}
.showPanel.switchButton .button-cover {
	position: relative;
	right: -4px;
}

.zoombtnHover:hover svg{
	color: #000;
	}
.zoomWrapper{
	display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  max-width: 50px;
  /* background: rgb(255, 255, 255); */
  border-radius: 2px;
  padding: 0px;
  z-index: 1;
  /* box-shadow: 0px 0.5px 1.5px #0000001a; */
}
.defaultScreenView{
	/* background: red, */
};

.fullScreenView {
	background: #fff;
	/* @media (min-width: 769px) {
		background: #ffffffeb;
		padding: 18px 20px;
		position: fixed;
		left: 1%;
		max-width: 320px;
		top: 13%;
  } */
	
};
@media (max-width: 840px){
	#button-13 .knobs span {
		top:2px
	}
}

@media (min-width:936px) {
	.fullScreenView {
		background: rgba(255, 255, 255, .95);
		padding: 1.75rem 1.25rem 1.35rem !important;
		position: fixed;
		left: 1%;
		/* Note: update the corresponding constant {FULL_VIEW_POSITION_SHIFT_IN_PIXELS} when updating max-wdith value */
		max-width: 366px;
		top: 9.5%;
		
	};
	.toggle-button-cover .button {
		z-index: 0;
	}

}
/* .NonView{
	flex-direction:row !important;
	@media  (max-width: 450px) {
		flex-direction:column !important;
	}
}
.NonView{
	flex-direction:row !important;
	@media  (max-width: 450px) {
		flex-direction:row !important;
	}
	@media  (max-width: 360px) {
		flex-direction:column !important;
	}
} */
@media  (max-width: 480px) {
	.CalculateBtn{
		order: 3;
	}
}
.CalculateBtn.showLoader{
	border-radius: 99px !important;
}
.CalculateBtn.showLoader:after {
	height: 14px !important;
	width: 14px !important;
}
@media  (max-width: 480px) {
	.CalculateBtn{
		order: 3;
	}
}


.loaderbefore.showLoader:after {
	height: 20px !important;
	width: 20px !important;
}

/***Facet Modal***/
/* .popup-modal-wrap{
	position: relative;
} */
 .facet-label-btn-wrap{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	padding-right: 1rem;
 }
.popup-modal-wrap .set-facet-btn{
	background: var(--secondary);
		border:1px solid var(--secondary) ;
    padding: .567rem 1.5rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #2E384D;
		box-shadow: none;
		font-family: 'Inter';
		font-weight: 600;
		border-radius: 5px;

}
.modal-facet-info-wrap {
	overflow-y: auto;
	height: calc(100vh - 100px);
	padding-right: .5rem;
}

/**Modal Scrollbar**/
.modal-facet-info-wrap::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px transparent;
	border-radius: 3px;
	background-color: #F5F5F5;
}

.modal-facet-info-wrap::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.modal-facet-info-wrap::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px transparent;
	background-color: rgb(211, 211, 211);
}


.modal-facet-info{
	padding: 1.125rem .85rem;
	background: #F8F9FF;
	box-shadow: #DCDFF1 0px 1px 4px;
	border: 1px solid #dfdfdf;
	border-radius: 8px;
	margin-bottom: .815rem;
}
.modal-facet-info .degree{
	position: relative;
	top: 1px;
	line-height: 1.2;
}
.modal-facet-info.active{
	background: #e3ebff;
}
.facet-label_name{
	background: #DEE2EB;
	font-size: 1rem;
	color: #28373E;
	line-height: 1.25;
	font-family: 'Inter';
	font-weight: 600;
	padding: .4rem 1rem .35rem 1rem;
	margin-bottom: .65rem;
	display: inline-flex;
	border-radius: 2px;
	box-shadow: #DCDFF1 0px 1px 1px;
}
.modal-facet-info.active .facet-label_name{
	background: #0C3668;
	color: #fff;
}
.azimuth-heading{
	font-family: 'Inter';
	font-weight: 500;
	font-size: 1rem;
	color: #2E384D;
	margin: 0 0px .5rem;
	line-height: 1.2;
	font-size: 1.125rem;
}
.azimuth-heading span{
	margin-left: 5px;
	color: #28373E;
	font-family: 'Inter';
	font-weight: 400;
	font-size: 90%;
}
.azimuth-heading span sup{
	padding-left: 1px;
}
.facet-slider-slope-info:not(facet-slope-wrap){
	margin-bottom: 10px;
}
.facet-slider-wrap .pitch-label{
	color: #64686A;
	font-family: 'Inter';
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.25;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: .5rem;
}
.facet-slider-wrap .pitch-degree{
	font-size: 94%;
	font-weight: 400;
	padding-left: 2px;
	position: relative;
	top: -1px;
}
.facet-slope-wrap span{
	color: #64686A;
	font-family: 'Inter';
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.25;
	display: block;
}


.facet-slope-wrap span{
	padding-bottom: 6px;
}
.facet-slope-wrap button{
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border: 1px solid #B9B8B8;
	background: #fff;
	color:#64686A;
	font-size: 0.875rem;
	text-transform: capitalize;
	line-height: 1.358;
	padding: 5px .85rem 4px .85rem;
}
.facet-slope-wrap button.active{
	border-color: var(--primary);
	background: #fff;
	color:var(--primary);
}
.facet-slope-wrap button:hover,
.facet-slope-wrap button:focus{
	border-color: var(--primary);
	background: #fff;
	color:var(--primary);
	opacity: .9;
}


.EnableSliderWrapper .toolbar-btn-wrap button:not(.active):not(:disabled):hover,
.EnableSliderWrapper .toolbar-btn-wrap button:not(.active):not(:disabled):focus{
	background: #F3F3F3 !important;
}

.EnableSliderWrapper .toolbar-btn-wrap button:disabled {
	 	cursor: not-allowed !important;
		 opacity: 100%;
 }
 .EnableSliderWrapper .toolbar-btn-wrap button:disabled *{
	opacity: .85;
 }
 .EnableSliderWrapper .toolbar-btn-wrap button{
	transition: all 2s ease;
 }

 /***Toolbar Label Properties***/

.toolbar-label{
	font-family: Inter;
	font-weight: 500;
	color: var(--label);
	padding-right: 0px;
	line-height: normal;
	font-size: 0.875rem;
	
}


/* @media (max-width:1321px){
	.toolbar-label{
		font-size: 0.813rem;
	}
} */

.buttonLabel{
	font-size: 0.813rem;
	color: #4A525A;
	transition-delay: 1s;
	font-family: Inter;
	position:relative;

	@media (max-width: 1298px) {
		font-size: 0.688rem;
	}
	@media (max-width: 1399px){
		display: none;
	}
	@media (max-width: 1340px){
			/* display: none; */
	}
}
.buttonIcon{
	margin-left: 6.5px;
	cursor: pointer;
}
.buttonIcon svg{
	position: relative;
	top: 1px;
	right: -1px;
}
.btnHover:not(.active):hover,
.btnHover:not(.active):focus{
	background:#F3F3F3 !important ;
}
button.save-btn{
	background: var(--secondary);
	border-color: var(--secondary);
}
button.CalculateBtn{
		font-size: 0.906rem;
		border-radius: 91.4939px;
		padding: 0.5em 14px;
		box-shadow: rgba(100,100,100,0.12) 0px 2.25911px 4px;
}
/* .panel-checkbox-label-wrap{
	display: flex;
	gap: 0.5rem;
	align-items: center;
	color: #64686A;
	font-size: 0.875rem;
	white-space: nowrap;
	font-family:'Inter';
	font-weight: 500;
} */
/* .panel-checkbox-wrap{
	width:19px;
	height:19px;
	border-radius:3px;
	display: flex;
	justify-content: center;
	align-items:center;
	color: #64686A;
	font-size: 0.875rem;
	flex-shrink: 0;
} */
/* .panel-checkbox-wrap svg {
	width: 11.5px;
} */
.fullScreenView #button-13.without-label .checkbox~ .layer {
	background: #ffffff;
}
.cancel-btn:hover,
.cancel-btn:focus{
	background: #fdfdfd !important
}

/***Responsive***/
@media (max-width: 1360px) {
	.buttonTitle{
	font-size: 0.688rem;
	}
	.buttonIcon{
		margin-left: 5px;
		width:8px;
	}
	.buttonIcon svg{
		width:10px;
	}
}
@media (max-width: 1325px) {
	.toolbar-label{
		font-size: 0.813rem;
	}
	.buttonLabel{
		font-size: 0.75rem;
	}
	button.loaderbefore{
		font-size: 0.813rem !important;
	}
	.switchMapping select{
		font-size: 0.813rem !important;
		padding: 6px 34px 6px 36px !important;
	}
	.switchMapping svg.imageIcon{
		width:22px
	}
	
	.switchMapping svg.arrowDown{
		width: 10px;
	}
	button.CalculateBtn{
		font-size: 0.875rem;
	}
	/* .panel-checkbox-label-wrap{
		gap: 0.45rem;
	} */
	
	/* .panel-checkbox-wrap{
		width:17px;
		height:17px;
	} */
	/* .panel-checkbox-wrap svg{
		width:11px;
		height: 9px;
	} */
}





.slider-bar:hover{
	opacity: 1;
}
.react-select-wrap .css-13cymwt-control{
	border-color: #e9e9e9;
	padding: 0.225rem 0.15rem;
	min-width: 226px;
	font-size: .925rem;
}
.react-select-wrap .css-13cymwt-control:hover{
	border-color: #dcdcdc;
}
.react-select-wrap .css-1xc3v61-indicatorContainer{
	padding: 4.5px 8px;
}
.w-full{
	width: 100%;
}
.relative{
	position: relative;
}
.bar-loader {
	position: absolute;
	top:0;
	left: 0;
	right: 0;
  width: 100%;
  padding-bottom: 8px;
  background: linear-gradient(var(--primary) 0 0) 0 100%/0% 3px no-repeat;
  animation: l2 3s linear infinite;
}
@keyframes l2 {to{background-size: 100% 3px}}


.save-btn	.save-arrow-icon {
    position: absolute;
    right: 20px;
}

button.delete-fecet-fullview {
	background: #f7afaf !important;
}
.fullScreenView .showPanel.switchButton .button-cover {
	right: -4px;
}